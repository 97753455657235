.fieldRoot {
  min-width: 100%;
  position: relative;
}

.fieldLabel {
  font-family: "Avenir";
  font-size: 12px !important;
  font-weight: 500 !important;
  padding-left: 10px;
  color: #293146;
  margin-bottom: 5px;
}

.fieldInput {
  padding: 5px 10px;
  color: #293146;
  width: 100%;
  height: 40px;
  display: block;
  background: none;
  border: 1px solid #00000029;
  font-size: 14px;
  font-weight: 500 !important;
  font-family: "Avenir";
  padding-left: 10px;
  padding-right: 50px;
  user-select: none;
  box-sizing: border-box;
}
.fieldInput:focus {
  outline: none;
  border: 1px solid #2f3138;
}

.fieldRoot .eye {
  position: absolute;
  top: 67%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 22px;
  cursor: pointer;
  opacity: 0.5;
}

.eye .MuiSvgIcon-root {
  opacity: 0.5;
  font-size: 18px;
}
.eye .MuiSvgIcon-root:hover {
  opacity: 0.9;
  font-size: 18px;
}
.fieldInput:-webkit-autofill,
.fieldInput:-webkit-autofill:hover,
.fieldInput:-webkit-autofill:focus,
.fieldInput:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}
.fieldInput:-webkit-autofill {
  -webkit-text-fill-color: #011b60 !important;
}
.fieldRoot .startIcon {
  position: absolute;
  top: 67%;
  left: 8px;
  transform: translateY(-50%);
  font-size: 18px;
  cursor: pointer;
  opacity: 0.8;
}
.redText {
  color: #ea0f08;
  font-size: 10px;
}

.authPage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../resources/img/M8s\ header\ image\ copy.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  position: fixed;
  width: 100vw;
  z-index: -1;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

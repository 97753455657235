.button {
  font-size: 14px;
  color: #ffffff;
  background: #ea0f08;
  width: 100%;
  height: 45px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid #707070;
  position: relative;
  border-style: none;
}

.mini {
  font-size: 14px;
  color: #ffffff;
  background: #ea0f08;
  padding: 10px 50px;
  height: 45px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid #707070;
  position: relative;
}

.button:active {
  background: #252525;
}
.buttonDisabled {
  font-size: 14px;
  color: #fefefe;
  width: 100%;
  height: 45px;
  border: none;
  cursor: pointer;
  background: #f2a3a3;
  position: relative;
}

.button--loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

::-webkit-scrollbar {
  display: none;
}
body {
  margin: 0;
  font-family: "Avenir", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Avenir";

  src: local("Avenir"),
    url(./assets/fonts/Avenir/Avenir-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Avenir";
  font-weight: 600;
  src: local("Avenir"),
    url(./assets/fonts/Avenir/Avenir-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Avenir";
  font-weight: 900;
  src: local("Avenir"),
    url(./assets/fonts/Avenir/Avenir-Black.ttf) format("truetype");
}
